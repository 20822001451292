const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  //DOMAIN SERVER MỚI
  development: {
    api: "https://quyhoachxaydung-api.phutho.gov.vn/",
    domainUserSide: "http://localhost:3000",
    domainAdminSide: "http://localhost:3000",
    domainName: "",
    doMainNameGeoServer: "https://geoserver.phutho.gov.vn/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geoserver.phutho.gov.vn/geoserver/wms",
    datastore: 'phutho',
    gwcUrl: 'https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/'
  },
  production: {
    api: "https://quyhoachxaydung-api.phutho.gov.vn/",
    domainUserSide: "https://quyhoachxaydung.phutho.gov.vn",
    domainAdminSide: "https://quyhoachxaydung-admin.phutho.gov.vn",
    domainName: "quyhoachxaydung.phutho.gov.vn",
    doMainNameGeoServer: "https://geoserver.phutho.gov.vn/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geoserver.phutho.gov.vn/geoserver/wms",
    datastore: 'phutho',
    gwcUrl: 'https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/'
  },

  
  // geo.cgis.asia - geo cũ
  // geo-phutho.cgis.asia -geo mới

  // //DOMAIN SERVER CŨ
  // development: {
  //   api: "https://phutho-api.cgis.asia/",
  //   domainUserSide: "http://localhost:3000",
  //   // domainAdminSide: "https://admin.phutho.cgis.asia",
  //   domainAdminSide: "http://localhost:3000",
  //   domainName: "",
  //   doMainNameGeoServer: "https://geo-phutho.cgis.asia/geoserver/wfs",
  //   doMainNameGeoServerWMS: "https://geo-phutho.cgis.asia/geoserver/wms",
  //   datastore: 'phutho',
  // },
  // production: {
  //   api: "https://phutho-api.cgis.asia/",
  //   domainUserSide: "http://phutho.cgis.asia",
  //   domainAdminSide: "https://admin.phutho.cgis.asia",
  //   domainName: "phutho.cgis.asia",
  //   doMainNameGeoServer: "https://geo-phutho.cgis.asia/geoserver/wfs",
  //   doMainNameGeoServerWMS: "https://geo-phutho.cgis.asia/geoserver/wms",
  //   datastore: 'phutho',
  // },
};

module.exports = apiEnvironment[env];
