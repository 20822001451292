import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import {
  NotificationMessageType,
  TokenKey,
  setCookies,
  getUserInfo,
  removeCookies,
  DomainUserSide,
  GenerateDeviceId,
} from "../../utils/configuration";
import * as viVN from "../../languages/vi-VN.json";
import * as accountAction from "../../redux/store/account/account.store";
import "./auth.scss";
import ShowNotification from '../react-notifications/react-notifications';
import { LeftPopup } from './leftPopup';
import { Login } from './login/login';
import { Register } from './register/register';
import { ForgotPassword } from './forgot-password/forgot-password';
import UrlCollect from '../../common/url-collect';

export const ModalAuthen = (props) => {
  const { register, handleSubmit, errors, setError, clearErrors, getValues, watch } = useForm({ mode: "all", reValidateMode: "onBlur" });
  const { show, onHide, showTabRegister, showTabLogin, returnUrl } = props;
  const dispatch = useDispatch();
  const [isTabLogin, setIsTabLogin] = useState(true);
  const [isTabRegister, setIsTabRegister] = useState(false);
  const [isTabForgotPassword, setIsTabForgotPassword] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [isForgotSuccess, setIsForgotSuccess] = useState(false);
  const [tel, setTel] = useState("");

  const onCheckTel = (data) => {
    let err = false;
    if (!data) {
      setError("mobilePhone", { type: "required" });
      err = true;
    } else if (data.toString().length < 10) {
      setError("mobilePhone", { type: "pattern" });
      err = true;
    } else clearErrors(["mobilePhone"]);
    setTel(data);
    return err;
  };

  const onSubmit = (data) => {
    if (!data) return;
    if (isTabLogin && !isTabForgotPassword) {
      const DeviceId = GenerateDeviceId();
      accountAction
        .Login({
          email: data.email,
          password: data.password,
          rememberMe: true,
          returnUrl: returnUrl,
          DeviceId
        })
        .then(
          (res) => {
            if (res && res.content && res.content.token) {
              setCookies(TokenKey.token, res.content.token);
              setCookies(TokenKey.refreshToken, res.content.refreshToken);
              setCookies(TokenKey.DeviceId, DeviceId);
              setCookies("SHOW_FORM_LOGIN", false);
              if (res.content.returnUrl) {
                window.location.replace(res.content.returnUrl);
                removeCookies(TokenKey.returnUrl);
                return;
              }

              let user = getUserInfo();
              dispatch(accountAction.login(user));
              onHide();
            } else {
              ShowNotification(
                viVN.Errors.UnableHandleException,
                NotificationMessageType.Error
              );
            }
          },
          (err) => {
            ShowNotification(
              viVN.Errors[(err && err.errorType) || "UnableHandleException"],
              NotificationMessageType.Error
            );
          }
        );
    }
    if (!isTabLogin && !isTabForgotPassword) {
      if (onCheckTel(tel)) return;
      accountAction
        .Register({
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword,
          phoneNumber: tel,
          fullName: data.fullName
        })
        .then(
          (res) => {
            setIsRegisterSuccess(true);
          },
          (err) => {
            ShowNotification(
              viVN.Errors[(err && err.errorType) || "UnableHandleException"],
              NotificationMessageType.Error
            );
          }
        );
    }
    if (isTabForgotPassword) {
      if (!data) return;
      accountAction
        .ForgotPassword({
          email: data.email,
          returnUrl: DomainUserSide + UrlCollect.ResetPassword,
        })
        .then(
          (res) => {
            setIsForgotSuccess(true);
          },
          (err) => {
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
    }
  };

  const handleSelectTabLogin = () => {
    setIsTabForgotPassword(false);
    setIsTabRegister(false);
    setIsRegisterSuccess(false);
    setIsTabLogin(true);
  }

  const handleSelectTabRegister = () => {
    setIsTabForgotPassword(false);
    setIsTabLogin(false);
    setIsTabRegister(true)
  }

  useEffect(() => {
    if (!show) {
      setIsTabLogin(false);
      setIsTabForgotPassword(false);
      setIsRegisterSuccess(false);
      setIsForgotSuccess(false);
      setIsTabRegister(false);
    } else {
      setIsTabRegister(showTabRegister)
      setIsTabLogin(showTabLogin)
    }
  }, [show])

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Body>
        <div className="row auth-container">
          <LeftPopup />
          <div className="col-12 col-lg-6">
            <div className="login-form px-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="w-100"><div className="close pr-2" onClick={onHide}>x</div></div>
                  {!isTabForgotPassword && !isRegisterSuccess &&
                    <>
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <img
                          style={{ width: 40, height: 40 }}
                          src={require("../../assets/image/logo.png")}
                          alt="Logo"
                        />
                        <div className='title-logo'>
                          <div className='font-weight-bold'>Sở xây dựng tỉnh Phú Thọ</div>
                        </div>
                      </div>
                      {/* <div className="col-12 d-flex mt-4">
                        <div className="tab__title">
                          <span
                            className={isTabLogin ? 'is_active' : ''}
                            onClick={handleSelectTabLogin}
                            style={{ cursor: 'pointer' }}>
                            Đăng nhập
                          </span>
                          <span
                            className={isTabRegister ? 'is_active' : ''}
                            onClick={handleSelectTabRegister}
                            style={{ cursor: 'pointer' }}>
                            Đăng ký
                          </span>
                        </div>
                      </div> */}
                    </>
                  }
                  {isTabLogin && !isTabForgotPassword &&
                    <Login
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setIsTabForgotPassword={setIsTabForgotPassword}
                      setIsTabLogin={setIsTabLogin}
                    />
                  }
                  {isTabRegister && !isTabForgotPassword &&
                    <Register
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      watch={watch}
                      setError={setError}
                      getValues={getValues}
                      tel={tel}
                      setTel={setTel}
                      isRegisterSuccess={isRegisterSuccess}
                    />
                  }
                  {isTabForgotPassword &&
                    <ForgotPassword
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setIsTabLogin={setIsTabLogin}
                      setIsTabForgotPassword={setIsTabForgotPassword}
                      isForgotSuccess={isForgotSuccess}
                      onHide={onHide}
                    />
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}